const calculateAvgRating = (reviewsRating) => {
  if (!Array.isArray(reviewsRating)) {
    console.error('reviewsRating is not an array:', reviewsRating);
    return "";
  }

  const totalRating = reviewsRating.reduce((acc, item) => {
    const rating = typeof item === 'number' ? item : (typeof item === 'object' && 'rating' in item ? item.rating : null);
    return acc + (rating || 0);
  }, 0);

  const avgRating =
    totalRating === 0
      ? ""
      : totalRating === 1
      ? totalRating
      : (totalRating / reviewsRating?.length).toFixed(1);
  return {
    totalRating,
    avgRating,
  };
};

export default calculateAvgRating;
