import React from 'react'
import { useEffect, useState } from "react";
import axios from "axios";
import './footer.css'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo1.png'
import { BASE_URL } from "../../utils/config";
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation()
  const [contactInfo, setContactInfo] = useState([]);
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)

  const quick__links = [
    // {
    //   path: '/home',
    //   display: t('Header_Home')
    // },
    {
      path: '/about',
      display: t('Header_About')
    },
    {
      path: '/tours',
      display: t('Header_Tours')
    },
  ];

  const quick__links2 = [
    {
      path: '/gallery',
      display: t('GallerySection_Title')
    },
    {
      path: '/login',
      display: t('Header_Login')
    },
    {
      path: '/register',
      display: t('Header_Register')
    },
  ];


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/contactInfo/`);
        const firstContactInfo = response.data.find(info => info.showInHomePage === true);
        if (firstContactInfo)
          setContactInfo(firstContactInfo);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const year = new Date().getFullYear();

  const handleSubscribe = (e) => {
    e.preventDefault()
    if (!email) {
      setErrorMessage('Please Enter your email')
      return;
    }
    axios.post(`${BASE_URL}/newsLetterEmails/`, { email: email })
      .then((res) => {
        alert('Congratulations! You subscribed MEA-Travel Succssefuly')
        setErrorMessage(false)
        setEmail('');
      })
      .catch((err) => {})
  }

  return (
    <footer className="footer" style={{ zIndex: '2' }} id='footer'>
      <Container>
        <Row>
          <Col lg='3'>
            <div className="logo">
              <img src={logo} alt='' />
              <p>
                {t('Footer_Contact_Description')}
              </p>
              <div className="social__links d-flex align-items-start gap-4">
                <span>
                  <a href='https://www.tripadvisor.com/Attraction_Review-g294201-d27690660-Reviews-Mea_Travel-Cairo_Cairo_Governorate.html' target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="24" width="24" id="Tripadvisor-Logo--Streamline-Ultimate" style={{ verticalAlign: 'text-top' }}>
                      <desc>Tripadvisor Logo Streamline Icon: https://streamlinehq.com</desc>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M6.76908 17.4192C9.65804 17.4192 12 15.0773 12 12.1883c0 -2.88894 -2.34196 -5.2309 -5.23092 -5.2309 -2.88896 0 -5.23092 2.34196 -5.23092 5.2309 0 2.889 2.34196 5.2309 5.23092 5.2309Z" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M6.76918 13.9384c0.9665 0 1.75 -0.7835 1.75 -1.75s-0.7835 -1.75 -1.75 -1.75 -1.75 0.7835 -1.75 1.75 0.7835 1.75 1.75 1.75Z" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.231 13.9384c0.9665 0 1.75 -0.7835 1.75 -1.75s-0.7835 -1.75 -1.75 -1.75 -1.75 0.7835 -1.75 1.75 0.7835 1.75 1.75 1.75Z" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M2.7293 8.82977C4.73732 6.17864 8.14015 4.4353 12 4.4353c3.8599 0 7.2627 1.74334 9.2707 4.39447" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M17.2309 17.4192c2.889 0 5.2309 -2.3419 5.2309 -5.2309 0 -2.88894 -2.3419 -5.2309 -5.2309 -5.2309C14.342 6.9574 12 9.29936 12 12.1883c0 2.889 2.342 5.2309 5.2309 5.2309Z" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="m9.23948 16.7899 2.77472 2.7747 2.7747 -2.7747" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M17.231 6.9574h5.884" strokeWidth="1.5"></path>
                      <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" d="M6.76918 6.9574H0.885189" strokeWidth="1.5"></path>
                    </svg>
                  </a>
                </span>
                <span>
                  <a href='https://www.instagram.com/mea.traveladventures/'  target="_blank">
                    <i className="ri-instagram-line"></i>
                  </a>
                </span>
                <span>
                  <a href='https://www.facebook.com/meatravel0?mibextid=ZbWKwL' target="_blank">
                    <i className='ri-facebook-circle-line'></i>
                  </a>
                </span>
              </div>

            </div>


          </Col>
          <Col lg='3'>
            <h5 className='footer__link-title'>
              {t('Footer_MainLinkes')}
            </h5>
            <ListGroup className='footer__quick-links'>
            <ListGroupItem className='ps-0 border-0'>
            <a href="/home" className='ps-0 border-0 text-secondary' style={{textDecoration: 'none'}}>{t('Header_Home')}</a>
            </ListGroupItem>
              {
                quick__links.map((item, index) => (
                  <ListGroupItem key={index} className='ps-0 border-0'>
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))
              }
            </ListGroup>
          </Col>
          <Col lg='3'>
            <h5 className='footer__link-title'>
              {t('Footer_QuickLinks')}
            </h5>
            <ListGroup className='footer__quick-links'>
              {
                quick__links2.map((item, index) => (
                  <ListGroupItem key={index} className='ps-0 border-0'>
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))
              }
            </ListGroup>
          </Col>
          <Col lg='3'>
            <h5 className='footer__link-title'>
              {t('Footer_Contact')}
            </h5>
            <ListGroup className='footer__quick-links'>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span><i className='ri-map-pin-line'></i></span>
                  {t('Footer_Contact_Address')}
                  :
                </h6>
                <p className='mb-0'>
                  {t('Footer_Contact_Address_Data')}
                </p>
              </ListGroupItem>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span><i className='ri-mail-line'></i></span>
                  {t('Footer_Contact_Email')}
                  :
                </h6>
                <p className='mb-0'> {contactInfo ? contactInfo.email : t('Footer_Contact_Email_Data')}</p>
              </ListGroupItem>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span><i className='ri-phone-fill'></i></span>
                  {t('Footer_Contact_Phone')}
                  :
                </h6>
                <p className='mb-0'> +020 {contactInfo ? contactInfo.phone : '1011169021'}</p>
              </ListGroupItem>
            </ListGroup>
            <Row>
              <div className="input-group mb-3 mt-4">
                <input type="text" className="form-control" value={email} placeholder={t('SubscribeSection_Input_Placeholder')} onChange={(e)=>setEmail(e.target.value)}/>
                <button className="btn newsletter__btn" type="button" onClick={handleSubscribe}>{t('SubscribeSection_Button')}</button>
              </div>
              {errorMessage && <div>{errorMessage}</div>}
            </Row>
          </Col>
          <Col lg='12' className='text-center pt-5'>
            <p className="copyrights">Copyright {year} - All rights reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
